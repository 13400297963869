.container {
  width: 100%;
  background: #fff;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: #666;
  padding: 0;
  position: relative;
}

.sidebar {
  background: rgba(0, 0, 0, 0.1);
  width: 300px;
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.sidebar > a,
.sidebar > button {
  display: block;
  color: #555;
  padding: 1em;
  font-size: 1em;
}

.wrapper {
  width: 100%;
}

:global * {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  list-style: none;
  quotes: none;
  font-style: normal;
  outline: none;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
}
:global *:focus {
  outline: none;
}
:global body {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  font-family: "Montserrat", sans-serif;
  background: #fff;
  font-size: 16px;
}
.conntetWrapper {
  padding: 0 0 0 0;
  width: 100%;
  min-height: 100vh;
  button {
    background: none;
  }
}
